<template>
  <div>
    <div class="detailBody pb-3 px-7">
      <div class="">
        <div class="flex justify-between items-center">
          <h4
            contenteditable="true"
            id="taskName"
            class="text-lg font-medium py-3 focus:border-black-50 focus:border"
            @focusout="emitUpdateTaskName"
            v-if="myAbilities.can('update_task', 'pms')"
          >
            {{ Task.name }}
          </h4>
          <div v-else class="text-lg font-medium py-3">
            {{ Task.name }}
          </div>
          
        </div>
        <div
          class="
            py-2
            flex
            justify-between
            bg-gray-200
            border-0 border-b border-gray-300
          "
        >
          <div class="flex px-2" v-if="Task.end_time == null">
            <div
              class="px-1"
              v-if="Task.is_pause == 1 && Task.start_time != null"
            >
              <i
                class="fas fa-play"
                @click="resumeTask"
                v-if="myAbilities.can('task_resume', 'pms')"
              ></i>
            </div>
            <div
              class="px-1"
              v-if="Task.is_pause == 0 && Task.start_time != null"
            >
              <i
                class="fas fa-pause"
                @click="pauseTask"
                v-if="myAbilities.can('task_pause', 'pms')"
              ></i>
            </div>
            <div class="px-1" v-if="Task.start_time == null">
              <button
                class="px-2 py-1 bg-blue-500 rounded text-white-100"
                @click="startTask"
                v-if="myAbilities.can('task_start', 'pms')"
              >
                Start Task
              </button>
            </div>
            <div class="px-1" v-if="Task.start_time != null">
              <button
                class="px-2 py-1 bg-blue-500 rounded text-white-100"
                @click="endTask"
                v-if="myAbilities.can('task_end', 'pms')"
              >
                End Task
              </button>
            </div>
          </div>
          <div
            class="px-2"
            v-if="Task.start_time != null && Task.end_time == null"
          >
            <p>Start time: {{ Task.start_time | moment }}</p>
          </div>
          <div class="px-2" v-if="Task.end_time != null">
            <p>End time: {{ Task.end_time | moment }}</p>
          </div>
          <div class="px-2" v-if="Task.end_time != null">
            <p>Duration: {{ Task.duration }}</p>
          </div>
        </div>
      </div>

      <div class="details">
        <div class="overflow-x-auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
          >
            <thead
              class="
                text-xs text-black-50
                font-normal
                dark:bg-gray-700 dark:text-gray-400
              "
            >
              <tr class="text-black-50">
                <td class="px-1 py-2">Asignee</td>
                <td class="px-1 py-2">
                  <span v-if="this.projectTask">
                    <multiselect
                      v-if="myAbilities.can('update_task', 'pms')"
                      class="
                        text-xs
                        w-2/3
                        border-0
                        mb-0
                        placeholder-mb-0
                        text-black-50
                      "
                      :options="Project.member"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      placeholder="Select"
                      :show-labels="false"
                      name="userSelect"
                      id="editUser"
                      track-by="id"
                      label="name"
                      v-model="Task.user"
                      @select="assignTaskTo"
                      :disabled="!Project.is_add_task"
                    >
                      <!--  -->
                    </multiselect>
                  </span>

                  <multiselect
                    v-if="this.myTask"
                    class="
                      text-xs
                      w-2/3
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                    "
                    :options="options"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    placeholder="Select"
                    :show-labels="false"
                    name="userSelect"
                    id="editUser"
                    track-by="id"
                    label="name"
                    v-model="Task.user"
                    @select="assignTaskTo"
                    disabled
                  >
                  </multiselect>
                </td>
              </tr>
            </thead>
            <tbody class="text-xs">
              <tr class="bg-white-100 text-black-50">
                <td class="px-1 py-2">Due Date</td>
                <td class="px-1 py-2">
                  <date-picker
                    v-if="myAbilities.can('update_task', 'pms')"
                    class="text-xs datepicker w-2/3 pb-0"
                    valueType="YYYY-MM-DD"
                    type="date"
                    placeholder="Deadline"
                    @change="updateTaskDeadline"
                    v-model="Task.deadline"
                  >
                  </date-picker>

                  <date-picker
                    v-else
                    class="text-xs datepicker w-2/3 pb-0"
                    valueType="YYYY-MM-DD"
                    type="date"
                    placeholder="Deadline"
                    v-model="Task.deadline"
                    disabled
                  >
                  </date-picker>
                </td>
              </tr>
              <tr class="bg-white-100 text-black-50">
                <td class="px-1 py-2">Projects</td>
                <td class="px-1 py-2">
                  <i class="fas fa-circle text-xs text-red-400"></i>
                  {{ Task.project.name }}
                </td>
              </tr>
              <tr class="bg-white-100 text-black-50">
                <td class="px-1 py-2">
                  <i class="fas fa-chevron-circle-down text-xs"></i>
                  Priority
                </td>
                <td class="px-1 py-2">
                  <multiselect
                    v-if="myAbilities.can('update_task', 'pms')"
                    class="
                      text-xs
                      w-2/3
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                    "
                    :options="TaskPriority"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder="Select "
                    name="priority"
                    id="priority"
                    track-by="id"
                    label="value"
                    @input="updateTaskPriority"
                    v-model="Task.task_priority"
                  >
                  </multiselect>
                <!-- when no permission to update task -->
                  <multiselect
                    v-else
                    class="
                      text-xs
                      w-2/3
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                    "
                    disabled
                    :options="TaskPriority"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder="Select "
                    name="priority"
                    id="priority"
                    track-by="id"
                    label="value"
                    v-model="Task.task_priority"
                  >
                  </multiselect>
                </td>
              </tr>
              <tr class="bg-white-100 text-black-50">
                <td class="px-1 py-2">
                  <i class="fas fa-chevron-circle-down text-xs"></i>
                  Status
                </td>
                <td class="px-1 py-2">
                  <multiselect
                    v-if="myAbilities.can('update_task', 'pms')"
                    class="
                      text-xs
                      w-2/3
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                    "
                    :options="Task.project.task_status"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder="Select"
                    name="statusSelect"
                    id="status"
                    track-by="id"
                    label="name"
                    @select="updateTaskStatus"
                    v-model="Task.task_status"
                  >
                  </multiselect>

                  <multiselect
                    v-else
                    class="
                      text-xs
                      w-2/3
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                    "
                    :options="Task.project.task_status"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder="Select"
                    name="statusSelect"
                    id="status"
                    track-by="id"
                    label="name"
                    disabled
                    v-model="Task.task_status"
                  >
                  </multiselect>
                  
                </td>
              </tr>
              <tr class="bg-white-100 text-black-50">
                <td class="px-1 py-2">
                  <i class="fas fa-chevron-circle-down text-xs"></i>
                  Estimated Duration
                </td>
                <td class="px-1 py-2">
                  <date-picker
                    class="text-xs datepicker w-2/3 pb-0"
                    valueType="HH:mm:ss"
                    type="time"
                    placeholder="Estimated Duration"
                    @close="updateTaskDuration"
                    @change="saveDurationValue"
                    v-model="Task.estimated_duration"
                  >
                  </date-picker>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pt-2">
            <label for="description" class="mb-4 text-sm text-black-50"
              >Description</label
            >
            <textarea
              v-if="myAbilities.can('update_task', 'pms')"
              name="decription"
              id="taskDesc"
              cols="30"
              rows="2"
              class="w-full border-black-50 hover:border rounded"
              v-model="Task.description"
            >
            </textarea>
            <div v-else class="py-2 px-1">{{ Task.description }}</div>
            <button
              class="px-3 py-1 bg-blue-500 text-white-100 rounded"
              @click="emitUpdateTaskDesc($event.target.value)"
            >
              Add
            </button>
          </div>
        </div>
        <!-- <div class="pt-2">
          <label for="description" class="mb-4 text-sm text-black-50"
            >Description</label
          >
          <textarea
            name="decription"
            id=""
            cols="30"
            rows="2"
            class="w-full border-black-50 hover:border rounded"
            v-model="Task.description"
          >
          </textarea>
        </div> -->
      </div>
    </div>
    <!-- {{Task.document}} -->
    <div>
      <div class="ProjectDocs border-9 py-3 border-b border-gray-300">
        <div class="px-5">
          <h4 class="font-bold text-gray-900">Files</h4>
        </div>

        <div
          v-if="myAbilities.can('get_task_document_files', 'pms')"
          class="flex flex-row flex-wrap text-sm font-medium"
        >
          <div
            class="
              w-sm
              m-4
              bg-white-100
              border border-gray-300
              rounded
              px-3
              py-4
            "
            v-for="doc in Task.document"
            :key="doc.id"
          >
            <div class="flex">
              <i class="fas fa-file text-4xl text-gray-600"></i>
              <div class="px-2 font-medium flex justify-between">
                <span class="truncate">{{ doc.fileoriginal_name }}</span>
                <span class="flex items-center"
                  ><i
                    class="fas fa-times text-black-50 text-xs ml-3"
                    @click="removeTaskDocument(doc.id)"
                    v-if="myAbilities.can('delete_task_documents', 'pms')"
                  ></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Task comments -->
    <div v-if="myAbilities.can('get_task_comments', 'pms')">
      <div class="px-5 py-2" v-if="Task.comment.length > 0">
        <h4 class="font-bold text-gray-900">Comments</h4>
      </div>

      <ul class="overflow-y-auto w-full" v-if="Task.comment.length != 0">
        <li
          class="py-2 px-7 border-0 border-b border-gray-200 w-full"
          v-for="comment in Task.comment"
          :key="comment.id"
        >
          <div class="flex">
            <img
              class="w-8 h-8 rounded-full mr-1"
              src="../../../../assets/images/user-img.png"
              alt="Rounded avatar"
            />
            <div class="w-full">
              <div class="flex">
                <div class="">
                  <p class="text-gray-700 font-medium">
                    <!-- {{ comment.user.name }} -->
                    {{ comment.user.name }}
                    <span class="text-black-50 text-xs">{{
                      comment.created_at.slice(
                        0,
                        comment.created_at.indexOf("T")
                      )
                    }}</span>
                  </p>
                </div>
                <span class="ml-3"
                  ><i
                    class="fas fa-pen text-xs text-black-50"
                    @click="editComment(comment)"
                    v-if="myAbilities.can('update_comment', 'pms')"
                  ></i
                ></span>
              </div>
              <div class="flex justify-between">
                <p class="text-sm textblack-50">{{ comment.description }}</p>
                <span
                  ><i
                    class="fas fa-trash text-sm text-black-50"
                    @click="removeComment(comment.id)"
                    v-if="myAbilities.can('delete_comment', 'pms')"
                  ></i
                ></span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Comments -->
    <footer
      class="
        py-3
        px-5
        rounded-b
        border-t border-gray-200
        dark:border-gray-600
        shadow-md
      "
    >
      <div class="">
       
        <textarea
          name="comment"
          id=""
          class="w-full border border-gray-400 rounded resize-none"
          v-model="comment.description"
        ></textarea>
      </div>
      <div class="flex justify-end">
        <button
          class="p-2 bg-blue-500 text-white-100 rounded"
          @click="addComment"
          v-if="showAddBtn"
        >
          Comment
        </button>
        <button
          class="p-2 bg-blue-500 text-white-100 rounded"
          @click="updateComment"
          v-if="showUpdateBtn"
        >
          Update Comment
        </button>
      </div>
    </footer>
  
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

import $ from "jquery";
export default {
  name: "Details",
  
  data() {
    return {
      isPause: false,
      isResume: false,
      showAddBtn: true,
      showUpdateBtn: false,
      comment: {
        description: "",
        id: "",
      },
      options: ["Ali", "Maham"],     
      myTask: false,
      projectTask: false,
      currentDuration: null,
    };
  },
  filters: {
    moment(date) {
      return moment(date).format("MMMM DD, YY. hh:mm a");
    },
  },
  methods: {
    ...mapActions([
      "fetchTask",
      "deleteTaskDocument",
      "updateTask",
      "storeTaskComment",
      "storeMyTaskComment",
      "updateTaskComment",
      "deleteTaskComment",
      "deleteMyTaskComment",
      "startTaskTimmer",
      "resumeTaskTimmer",
      "pauseTaskTimmer",
      "endTaskTimmer",
      "startMyTaskTimmer",
      "resumeMyTaskTimmer",
      "pauseMyTaskTimmer",
      "endMyTaskTimmer",
      "fetchPriority",
      "updateMyTask",
    ]),
    ...mapMutations(["toggleLoading"]),

// Here using functions define in List.vue
    // update task name 
    emitUpdateTaskName() {
      let edit = $("#taskName");
      this.$emit("update-task-name", edit[0].innerText, this.Task.id);
    },

    // update task description
    emitUpdateTaskDesc() {
      let desc = $("#taskDesc");
      let descr = desc[0].value;
      this.$emit("update-task-desc", descr, this.Task.id, this.Task.name);
    },
    // end 


    //------comment --------
    async addComment() {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.storeMyTaskComment({
          description: this.comment.description,
          task_id: this.$route.params.taskId,
        });
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.comment.description = "";
        } else {
          this.$toasted.error(res.msg);
        }
      } else {
        let res = await this.storeTaskComment({
          description: this.comment.description,
          task_id: this.$route.params.taskId,
        });
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.comment.description = "";
        } else {
          this.$toasted.error(res.msg);
        }
      }
    },
    
    // update comment
    async updateComment() {
      this.toggleLoading(true);
      let res = await this.updateTaskComment(this.comment);
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment.description = "";
        this.comment.id = "";
        this.showUpdateBtn = false;
        this.showAddBtn = true;
      } else {
        this.$toasted.error(res.msg);
      }
    },

    // delete task docs
    async removeTaskDocument(id) {
      this.toggleLoading(true);
      let res = await this.deleteTaskDocument({
        id: id,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    },

    // update task status
    async updateTaskStatus(item) {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.updateMyTask({
          id: this.Task.id,
          name: this.Task.name,
          status_id: item.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      } else {
        let res = await this.updateTask({
          id: this.Task.id,
          name: this.Task.name,
          status_id: item.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      }
      this.toggleLoading(false);
    },

    //update task priority
    async updateTaskPriority(item) {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.updateMyTask({
          id: this.Task.id,
          name: this.Task.name,
          priority: item.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      } else {
        let res = await this.updateTask({
          id: this.Task.id,
          name: this.Task.name,
          priority: item.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      }
      this.toggleLoading(false);
    },
    
    //task assign to
    async assignTaskTo(item) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: this.Task.id,
        name: this.Task.name,
        assign_to: item.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //task deadline
    async updateTaskDeadline(item) {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.updateMyTask({
          id: this.Task.id,
          name: this.Task.name,
          deadline: item,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      } else {
        let res = await this.updateTask({
          id: this.Task.id,
          name: this.Task.name,
          deadline: item,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      }

      this.toggleLoading(false);
    },
    //task deadline
    saveDurationValue(item) {
      this.currentDuration = item;
    },
    async updateTaskDuration() {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.updateMyTask({
          id: this.Task.id,
          name: this.Task.name,
          estimated_duration: this.currentDuration,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      } else {
        let res = await this.updateTask({
          id: this.Task.id,
          name: this.Task.name,
          estimated_duration: this.currentDuration,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
      }

      this.toggleLoading(false);
    },
    async removeComment(commentId) {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.deleteMyTaskComment({
          id: this.Task.id,
          comment_id: commentId,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      } else {
        let res = await this.deleteTaskComment({
          id: this.Task.id,
          comment_id: commentId,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      }
    },

    //edit task comment
    editComment(comment) {
      this.comment.description = comment.description;
      this.comment.id = comment.id;
      this.content = comment.description;
      this.showAddBtn = false;
      this.showUpdateBtn = true;
    },

    //Task timmer
    async startTask() {
      this.toggleLoading(true);
      if (this.myTask) {
        let res = await this.startMyTaskTimmer({ id: this.Task.id });
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.isPause = true;
        } else {
          this.$toasted.error(res.msg);
        }
      } else {
        let res = await this.startTaskTimmer({ id: this.Task.id });
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.isPause = true;
        } else {
          this.$toasted.error(res.msg);
        }
      }
    },
    // resume task timer
    async resumeTask() {
      this.toggleLoading(true);
      if(this.myTask) {
let res = await this.resumeMyTaskTimmer({ id: this.Task.id });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.isPause = false;
        this.isResume = true;
      } else {
        this.$toasted.error(res.msg);
      }
      }else{
let res = await this.resumeTaskTimmer({ id: this.Task.id });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.isPause = false;
        this.isResume = true;
      } else {
        this.$toasted.error(res.msg);
      }
      }
      
    },

    // pause task timer
    async pauseTask() {
      this.toggleLoading(true);
      if(this.myTask) {
        let res = await this.pauseMyTaskTimmer({ id: this.Task.id });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.isPause = true;
        this.isResume = false;
      } else {
        this.$toasted.error(res.msg);
      }
      }else {
      let res = await this.pauseTaskTimmer({ id: this.Task.id });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.isPause = true;
        this.isResume = false;
      } else {
        this.$toasted.error(res.msg);
      }
      }
    },
    async endTask() {
      this.toggleLoading(true);
      if(this.myTask) {
let res = await this.endMyTaskTimmer({ id: this.Task.id });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      }else{
        let res = await this.endTaskTimmer({ id: this.Task.id });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      }
      
    },
  },

  computed: {
    ...mapGetters(["Task", "Project", "TaskId", "TaskPriority", "myAbilities"]),
  },
  async created() {

    let url = window.location.href;
    let segment = url.split("/");
    //  segemnt 3 to pop myTask / here my task is set to the default route so i'm popping myTaskDetail using segemnt 4
    let domain = segment[3];
    let ProjectDomain = segment[3];
    if (domain == "myTask") {
      this.myTask = true;
      this.projectTask = false;
    } else if (ProjectDomain == "project") {
      this.projectTask = true;
      this.myTask = false;
    }
  },
};
</script>